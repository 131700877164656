<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import RequestsTable from '@/components/RequestsTable'
import PaginationNav from '@/components/PaginationNav'

const store = useStore()
const users = ref([])
const current_page = ref(0)
const from = ref(0)
const to = ref(0)
const total = ref(0)
const prev_url = ref(null)
const next_url = ref(null)

onMounted(() => {
  loadRequests(`${process.env.VUE_APP_API_URL}/admin/requests`)
})

const loadRequests = async(url) => {

  if(!url)return

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + store.state.auth.token
      }
    })
    console.log(response)
    users.value = response.data.data
    current_page.value = response.data.current_page
    from.value = response.data.from
    to.value = response.data.to
    total.value = response.data.total
    prev_url.value = response.data.prev_page_url
    next_url.value = response.data.next_page_url
  } catch(error) {
    console.log(error)
  }
}

const prev = () => {
  loadRequests(prev_url.value)
}

const next = () => {
  loadRequests(next_url.value)
}

</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Creator Requests</h1>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <RequestsTable :users="users" />
            <PaginationNav
              :from="from" :to="to" :total="total"
              @prev="prev" @next="next" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>