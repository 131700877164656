import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";

import HomeView from '@/views/HomeView'
import LoginView from '@/views/LoginView'
import ReleasesView from '@/views/ReleasesView'
import ListenersView from '@/views/ListenersView'
import LabelsView from '@/views/LabelsView'
import SubmissionsView from '@/views/SubmissionsView'
import CreatorRequestsView from '@/views/CreatorRequestsView'
import CreatorsView from '@/views/CreatorsView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresVisitor: true
    }
  },
  {
    path: '/releases',
    name: 'releases',
    component: ReleasesView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listeners',
    name: 'listeners',
    component: ListenersView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/labels',
    name: 'labels',
    component: LabelsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/submissions',
    name: 'submissions',
    component: SubmissionsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/creators',
    name: 'creators',
    component: CreatorsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/creators/requests',
    name: 'creator-requests',
    component: CreatorRequestsView,
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters['auth/loggedIn']) {
      next({ name: 'listeners' });
    } else {
      next();
    }

  }else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/loggedIn']) {
      next();
    } else {
      next({ name: 'login' });
    }

  }

  next();

});

export default router
